// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lot-1-js": () => import("./../src/pages/lot-1.js" /* webpackChunkName: "component---src-pages-lot-1-js" */),
  "component---src-pages-lot-2-js": () => import("./../src/pages/lot-2.js" /* webpackChunkName: "component---src-pages-lot-2-js" */),
  "component---src-pages-lot-3-js": () => import("./../src/pages/lot-3.js" /* webpackChunkName: "component---src-pages-lot-3-js" */),
  "component---src-pages-lot-4-js": () => import("./../src/pages/lot-4.js" /* webpackChunkName: "component---src-pages-lot-4-js" */),
  "component---src-pages-lots-js": () => import("./../src/pages/lots.js" /* webpackChunkName: "component---src-pages-lots-js" */)
}

